<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-form
      :method.sync="method"
      :options="options">
      <DetailForm
        :procedureType="procedureType"
        :templateType="templateType"
        :method="method"
        :formData="formData"
        :v="$v">
      </DetailForm>
    </sgv-form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm.vue'
import {
  DETAIL_PROCEDURE,
  CREATE_PROCEDURE,
  UPDATE_PROCEDURE,
  DESTROY_PROCEDURE
} from './graph'

export default {
  metaInfo () {
    return {
      title: `${this.formData.name}` || `${this.title}`
    }
  },
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    procedureId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        name: '',
        machineId: null,
        machine: null,
        procedureInventoryId: null,
        procedureControlId: null,
        procedureQualityId: null,
        description: '',
        isActive: true,
        categories: []
      },
      method: 'info',
      listView: `Procedure${this.$form.capitalize(this.procedureType)}List`,
      isLoaded: false,
    }
  },
  validations: {
    formData: {
      name: { required },
      machineId: { required },
      procedureInventoryId: { required },
      procedureControlId: { required },
      procedureQualityId: { required },
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`procedure:${this.procedureType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`procedure:${this.procedureType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_PROCEDURE(this.templateType),
        variables: {
          procedureType: this.procedureType,
          procedureId: this.procedureId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.procedure)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isLoaded = true
      })
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'categories') {
          this.formData[key] = v[key].map(x => x.id)
          return
        }
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const input = {...v}
      const removeKeys = ['papers', 'machine']
      removeKeys.forEach(x => delete input[x])
      return input
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_PROCEDURE(this.templateType),
        variables: {
          procedureType: this.procedureType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, procedureId: res.data.createProcedure.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_PROCEDURE(this.templateType),
        variables: {
          procedureType: this.procedureType,
          procedureId: this.procedureId,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.setFormData(res.data.updateProcedure)
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_PROCEDURE(this.templateType),
        variables: {
          procedureType: this.procedureType,
          procedureId: this.procedureId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.procedureId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm
  }
}
</script>

<style lang="css" scoped>
</style>
