<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>

      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="รายละเอียด"
        :rows="3"
        v-model="formData.description">
      </sgv-input-textarea>

      <DetailFormInventory
        ref="machine"
        tag="machine"
        :procedureType="procedureType"
        :templateType="templateType"
        :disabled="$auth.disabled(method)"
        class="col-12"
        select="id"
        v-model="formData.machineId"
        :validations="[
          {text: 'required!', value: v.formData.machineId.$dirty && v.formData.machineId.$error}
        ]">
      </DetailFormInventory>

      <DetailFormProcedureInventory
        label="ควบคุมสินค้า"
        :procedureType="procedureType"
        :templateType="templateType"
        :disabled="$auth.disabled(method)"
        class="col-12"
        select="id"
        v-model="formData.procedureInventoryId"
        :validations="[
          {text: 'required!', value: v.formData.procedureInventoryId.$dirty && v.formData.procedureInventoryId.$error}
        ]">
      </DetailFormProcedureInventory>

      <DetailFormProcedureControl
        label="ควบคุมผลิต"
        :procedureType="procedureType"
        :templateType="templateType"
        :disabled="$auth.disabled(method)"
        class="col-12"
        select="id"
        v-model="formData.procedureControlId"
        :validations="[
          {text: 'required!', value: v.formData.procedureControlId.$dirty && v.formData.procedureControlId.$error}
        ]">
      </DetailFormProcedureControl>

      <DetailFormProcedureQuality
        label="ควบคุมคุณภาพ"
        :procedureType="procedureType"
        :templateType="templateType"
        :disabled="$auth.disabled(method)"
        class="col-12"
        select="id"
        v-model="formData.procedureQualityId"
        :validations="[
          {text: 'required!', value: v.formData.procedureQualityId.$dirty && v.formData.procedureQualityId.$error}
        ]">
      </DetailFormProcedureQuality>
    </div>

    <CategoryCheck
      :procedureType="procedureType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      v-model="formData.categories">
    </CategoryCheck>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          inline
          label="ใช้งาน"
          v-model="formData.isActive">
        </sgv-input-check>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryCheck from './CategoryCheck.vue'
import DetailFormInventory from './DetailFormInventory'
import DetailFormProcedureInventory from './DetailFormProcedureInventory'
import DetailFormProcedureControl from './DetailFormProcedureControl'
import DetailFormProcedureQuality from './DetailFormProcedureQuality'

export default {
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  watch: {
    'formData.machine': {
      handler (v) {
        if (v) {
          this.$nextTick(() => {
            this.$refs.machine.setGroup(v.type)
          })
        }
      }
    }
  },
  components: {
    CategoryCheck,
    DetailFormInventory,
    DetailFormProcedureInventory,
    DetailFormProcedureControl,
    DetailFormProcedureQuality,
  }
}
</script>

<style lang="css">
</style>
