var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.v.formData.name.$dirty && _vm.v.formData.name.$error}
      ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รายละเอียด","rows":3},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('DetailFormInventory',{ref:"machine",staticClass:"col-12",attrs:{"tag":"machine","procedureType":_vm.procedureType,"templateType":_vm.templateType,"disabled":_vm.$auth.disabled(_vm.method),"select":"id","validations":[
        {text: 'required!', value: _vm.v.formData.machineId.$dirty && _vm.v.formData.machineId.$error}
      ]},model:{value:(_vm.formData.machineId),callback:function ($$v) {_vm.$set(_vm.formData, "machineId", $$v)},expression:"formData.machineId"}}),_c('DetailFormProcedureInventory',{staticClass:"col-12",attrs:{"label":"ควบคุมสินค้า","procedureType":_vm.procedureType,"templateType":_vm.templateType,"disabled":_vm.$auth.disabled(_vm.method),"select":"id","validations":[
        {text: 'required!', value: _vm.v.formData.procedureInventoryId.$dirty && _vm.v.formData.procedureInventoryId.$error}
      ]},model:{value:(_vm.formData.procedureInventoryId),callback:function ($$v) {_vm.$set(_vm.formData, "procedureInventoryId", $$v)},expression:"formData.procedureInventoryId"}}),_c('DetailFormProcedureControl',{staticClass:"col-12",attrs:{"label":"ควบคุมผลิต","procedureType":_vm.procedureType,"templateType":_vm.templateType,"disabled":_vm.$auth.disabled(_vm.method),"select":"id","validations":[
        {text: 'required!', value: _vm.v.formData.procedureControlId.$dirty && _vm.v.formData.procedureControlId.$error}
      ]},model:{value:(_vm.formData.procedureControlId),callback:function ($$v) {_vm.$set(_vm.formData, "procedureControlId", $$v)},expression:"formData.procedureControlId"}}),_c('DetailFormProcedureQuality',{staticClass:"col-12",attrs:{"label":"ควบคุมคุณภาพ","procedureType":_vm.procedureType,"templateType":_vm.templateType,"disabled":_vm.$auth.disabled(_vm.method),"select":"id","validations":[
        {text: 'required!', value: _vm.v.formData.procedureQualityId.$dirty && _vm.v.formData.procedureQualityId.$error}
      ]},model:{value:(_vm.formData.procedureQualityId),callback:function ($$v) {_vm.$set(_vm.formData, "procedureQualityId", $$v)},expression:"formData.procedureQualityId"}})],1),_c('CategoryCheck',{attrs:{"procedureType":_vm.procedureType,"templateType":_vm.templateType,"disabled":_vm.$auth.disabled(_vm.method)},model:{value:(_vm.formData.categories),callback:function ($$v) {_vm.$set(_vm.formData, "categories", $$v)},expression:"formData.categories"}}),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"inline":"","label":"ใช้งาน"},model:{value:(_vm.formData.isActive),callback:function ($$v) {_vm.$set(_vm.formData, "isActive", $$v)},expression:"formData.isActive"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }